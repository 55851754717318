import { Court } from './bo/court';
import { Component } from '@angular/core';
import {AngularFireDatabase} from 'angularfire2/database';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  courts: any[]; 

  constructor(db: AngularFireDatabase) {
    db.list('message').valueChanges().subscribe(courts => {
      this.courts = courts;
      console.log('constructor ran');
      console.log(this.courts);
    });
  }
}