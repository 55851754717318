import { Component, OnInit } from '@angular/core';
import { Court } from '../bo/court';
import { CourtService } from '../services/court.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
})
export class DashboardComponent implements OnInit {
  
  courts: any[];

  constructor(private courtService: CourtService) { }

  ngOnInit() {
    this.getCourts();
  }

  getCourts(): void {
    this.courtService.getCourts()
      .subscribe(courts => this.courts = courts.slice(0, 5));
  }
}