import { Injectable } from '@angular/core';
import { Court } from '../bo/court';
import {COURTS} from '../bo/mock-courts';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { Component } from '@angular/core';
import {AngularFireDatabase} from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})

export class CourtService {

  courts: any[]; 

  constructor(private messageService: MessageService,
              public db: AngularFireDatabase) {
      console.log('constructor ran for CourtService');
      console.log(this.courts);
  }
 
  getCourts(){

  var config = {
     apiKey: "AIzaSyCEJcVZMMPVadsvmEt5gYEkOmsci6nU_9s",
     authDomain: "vogellachat-6af3f.firebaseapp.com",
     databaseURL: "https://vogellachat-6af3f.firebaseio.com",
     storageBucket: "vogellachat-6af3f.appspot.com"};

  //firebase.initializeApp(config);

  // Get a reference to the database service
  //var database = firebase.database();


    this.messageService.add('CourtService: fetched courts in court service');
    
    //testing    return this.db.database().ref('courts').orderByChild('club');
        return this.db.list('courts').valueChanges();
        //    return of(courts);
  }

  getCourt(id: number): Observable<Court> {
    this.messageService.add(`CourtService: fetched court id=${id}`);
    return of(COURTS.find(court => court.id === id));
  }
}