import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourtsComponent  } from '../courts/courts.component';
import { DashboardComponent }   from '../dashboard/dashboard.component';
import { CourtDetailComponent }  from '../court-detail/court-detail.component';

const routes : Routes= [
{ path: 'courts', component: CourtsComponent },
{ path: 'dashboard', component: DashboardComponent },
{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
{ path: 'detail/:id', component: CourtDetailComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
