import { Component, OnInit, Input } from '@angular/core';
import { Court } from '../bo/court';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CourtService }  from '../services/court.service';

@Component({
selector: 'app-court-detail',
templateUrl: './court-detail.component.html',
styleUrls: ['./court-detail.component.css']
})
export class CourtDetailComponent implements OnInit {

@Input() court: Court;

constructor(
private route: ActivatedRoute,
private courtService: CourtService,
private location: Location
) {}

ngOnInit(): void {
this.getCourt();
}

getCourt(): void {
const id = +this.route.snapshot.paramMap.get('id');
this.courtService.getCourt(id)
.subscribe(court => this.court = court);
}
goBack(): void {
  this.location.back();
}
}
