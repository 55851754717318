/**
  This will create sample courts for testing.
  @since 20190502
  @author BJM
*/

import { Court } from './court';

export const COURTS: Court[] = [
   {id: 1,name: 'Court 1',club: 'Spa',type: 1, notes: 'Small'},
   {id: 2,name: 'Court 2',club: 'Spa',type: 1, notes: 'Small'},
   {id: 3,name: 'Court 3',club: 'Spa',type: 1, notes: ''},
   {id: 4,name: 'Court 4',club: 'Spa',type: 1, notes: ''},
   {id: 5,name: 'Court 5',club: 'Spa',type: 2, notes: ''}
];