import { Component, OnInit } from '@angular/core';
import { Court } from '../bo/court';
import { CourtService } from '../services/court.service';

@Component({
  selector: 'app-courts',
  templateUrl: './courts.component.html',
  styleUrls: ['./courts.component.css']
})

export class CourtsComponent implements OnInit {

  courts: any[]; 
      
  constructor(private courtService: CourtService) { }

  ngOnInit() {
    this.getCourts();
  }

  getCourts(): void {
    this.courtService.getCourts()
      .subscribe(courts => this.courts = courts);
  }

}
